<script setup>
  import cashbackList from './list'
  import cashbackDetails from './details'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const cashbackListRef = ref(null)

  const data = reactive({
    isInitialized: false, // 初始化是否完成
    detailsItem: {}
  })

  const isInitializedToggle = (status) => {
    data.isInitialized = status
  }

  // 切换类型
  const changeType = (type) => {
    type = type || 'cashbackList'
    userStore.setUserShowPage({ selectType: type })
  }

  // 跳转详情页
  const skipDetails = (item) => {
    data.detailsItem = item
    changeType('cashbackDetails')
  }

  const addRewards = () => {
    nextTick()
    cashbackListRef.value.addRewards()
  }

  onMounted(() => {
    // changeType('cashbackDetails')
  })
</script>

<template>
  <div>
    <div class="tab-header-box rowBC">
      <div class="pro-main-breadcrumb rowSC">
        <span>{{ $t('Home') }}</span>
        <img src="~img/down_icon.svg" @click="changeType('cashbackDetails')" />
        <b @click="changeType('cashbackList')">Cashback</b>
        <div class="rowCC" v-if="userStore.userShowPage.selectType === 'cashbackDetails'">
          <img src="~img/down_icon.svg" />
          <b @click="changeType('cashbackDetails')">Request Details</b>
        </div>
      </div>
      <div v-if="data.isInitialized && userStore.userShowPage.selectType === 'cashbackList'" class="add-rewards rowCC" @click="addRewards('add')">
        <div class="add-ico"></div>
        <div>Claim Cashback</div>
      </div>
    </div>
    <el-divider class="divider" />

    <div>
      <cashbackDetails v-if="userStore.userShowPage.selectType === 'cashbackDetails'" :item="data.detailsItem"></cashbackDetails>
      <cashbackList ref="cashbackListRef" v-else @isInitializedToggle="isInitializedToggle" @skipDetails="skipDetails"></cashbackList>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .tab-header-box {
    .pro-main-breadcrumb {
      width: fit-content;
      font-size: 13px;
      line-height: 30px;

      img {
        margin: 9px;
        width: 12px;
        height: 12px;
        transform: rotate(270deg);
      }
      b {
        cursor: pointer;
      }
    }
    .add-rewards {
      padding: 4px 8px;
      color: #fff;
      background: var(--btnBgColor);
      font-size: 16px;
      border-radius: 20px;
      cursor: pointer;
      .add-ico {
        background: url('https://cdn.qingmeidata.com/3411f440.png') no-repeat;
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }
    }
  }
  .divider {
    margin: 10px 0 30px 0;
  }
</style>
