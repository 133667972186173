<script setup>
  import orderOs from '@/views/order/compontent/order-os'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, inject } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const updataStatus = inject('updataStatus')

  const props = defineProps({
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    curIndex: {
      type: [String, Number]
    }
  })

  const orderOsRef = ref(null)
  const data = reactive({
    //订单状态 0未支付，1支付中，2待发货，3已发货，4已收货，5关闭CLOSED
    orderStatusJO: { CLOSED: -1, NOPAID: 0, PAYING: 1, PAYMENT_FAILED: 2, UNDISTRIBUTED: 3, DISTRIBUTED: 4, RECEIPTED: 5 }
  })

  /**
   * 根据订单状态和状态数组判断是否高亮状态图标
   * @param orderstatus
   * @param statusId
   * @returns {boolean}
   */
  const disableDiv = (orderstatus, statusId) => {
    return data.orderStatusJO[orderstatus] < data.orderStatusJO[statusId]
  }

  // 跳转订单详情页
  const toOrderDetail = (order) => {
    proxy.$router.push({
      name: 'ORDERMAIN',
      params: { orderId: props.orderInfo.mainOrderNo }
    })
  }

  // 跳转购买页
  const toPay = () => {
    // console.log(props.orderInfo, 'props.orderInfo')
    // orderOsRef.value.toPay(props.orderInfo)
    router.push({ name: 'OTHERPAYMENT', query: { orderId: `${props.orderInfo.mainOrderNo}`, paymentType: `${props.orderInfo.channel}` } })
  }

  // 上传凭证处理
  const handleCredentials = (order) => {
    orderOsRef.value.handleCredentials(props.orderInfo)
  }

  // 取消订单
  const cancelOrder = () => {
    console.log('取消订单')
    orderOsRef.value.cancelOrder(props.orderInfo, updataStatus, props.curIndex)
  }

  // 确认收货
  const confirmReceive = (order, index) => {
    data.curIndex = index
    orderOsRef.value.confirmReceive(props.orderInfo, updataStatus)
  }

  // 增加评论
  const addComment = () => {
    orderOsRef.value.toEvaluate(props.orderInfo.mainOrderNo, updataStatus)
  }
</script>

<template>
  <div class="opt-box rowEC">
    <template v-if="disableDiv(props.orderInfo.orderStatus, 'DISTRIBUTED') && props.orderInfo.orderStatus !== 'CLOSED'" data-remark="订单状态-取消订单">
      <div class="btn-text mt8" data-remark="取消订单" @click="cancelOrder">{{ $t('Cancel') }}</div>
    </template>
    <template v-if="props.orderInfo.orderStatus == 'DISTRIBUTED'" data-remark="订单状态-已发货">
      <div class="btn-text mt8" data-remark="确认收货" @click="confirmReceive(order, props.curIndex)">Received</div>
    </template>
    <div class="btn-text mt8" data-remark="查看详情" @click="toOrderDetail(order)">View Order</div>
    <template v-if="props.orderInfo.orderStatus === 'RECEIPTED'" data-remark="订单状态-已收货">
      <button class="btn hr-primary-btn mt8" data-remark="增加评论" @click="addComment">Review</button>
    </template>
    <template v-if="props.orderInfo.orderStatus === 'NOPAID' || props.orderInfo.orderStatus == 'PAYING' || props.orderInfo.orderStatus == 'PAYMENT_FAILED'">
      <button
        class="btn hr-primary-btn mt8"
        v-if="props.orderInfo.channel === 'CARD' || props.orderInfo.channel === 'EASY_PAISA' || props.orderInfo.channel === 'JAZZ_CASH'"
        @click="toPay(order)"
      >
        Pay Now
      </button>
      <button
        class="btn hr-primary-btn mt8"
        v-else-if="props.orderInfo.channel === 'BANK_TRANSFER'"
        data-remark="0:待审核/1通过/-1审核不通过/2待提交"
        @click="handleCredentials(order)"
      >
        {{ props.orderInfo.bankTransferStatus === 0 ? $t('Awaiting Review') : props.orderInfo.bankTransferStatus === -1 ? $t('Rejected') : $t('Upload Credentials') }}
      </button>
    </template>
  </div>
  <order-os ref="orderOsRef"></order-os>
</template>

<style lang="less" scoped>
  .opt-box {
    padding: 16px 24px;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    .btn-text {
      padding: 8px 16px;
      text-decoration: underline;
      cursor: pointer;
    }
    .btn {
      width: 148px;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 8px !important;
      cursor: pointer;
    }
    .ml8 {
      margin-left: 8px;
    }
  }
</style>
